define("partner-playbook-frontend/components/project-database/project-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    limit: 10,
    offset: 0,
    sortBy: 'ranking',
    sortAsc: true,
    search: null,
    searchEnabled: true,
    projectHeaders: [{
      name: 'Ranking',
      field: 'ranking',
      width: 'four',
      sortable: false
    }, {
      name: 'BD Ranking',
      field: 'bdRanking',
      width: 'four',
      sortable: false
    }, {
      name: 'Project Name',
      field: 'projectName',
      width: 'four',
      sortable: false
    }, {
      name: 'Interface Name',
      field: 'interfaceName',
      width: 'four',
      sortable: false
    }, {
      name: 'Interface Version',
      field: 'interfaceVersion',
      width: 'four',
      sortable: false
    }, {
      name: 'Industry Type',
      field: 'type',
      width: 'four',
      sortable: false
    }, {
      name: 'Project Summary',
      field: 'summary',
      width: 'four',
      sortable: false
    }, {
      name: 'Project Status',
      field: 'projectStatus',
      width: 'four',
      sortable: false
    }, {
      name: 'Channel Sales Director',
      field: 'vendor.channelSalesDirectorId',
      width: 'four',
      sortable: false
    }, {
      name: 'Integration Engineer',
      field: 'apiPocId',
      width: 'four',
      sortable: false
    }, {
      name: 'API Analyst',
      field: 'primaryPsPOCId',
      width: 'four',
      sortable: false
    }, {
      name: 'Pilot Analyst',
      field: 'bdPocId',
      width: 'four',
      sortable: false
    }, {
      name: 'inReview',
      field: 'inReview',
      width: 'four',
      sortable: false
    }],
    headers: Ember.computed(function () {
      return this.projectHeaders;
    }),
    actions: {
      handleRowClick: function handleRowClick(aProject) {
        this.handleRowClick(aProject);
      },
      filterChanged: function filterChanged(filter) {
        this.filterChanged(filter);
      },
      onTermUpdated: function onTermUpdated(search) {
        this.set('search', search);
        this.set('offset', 0);
      }
    }
  });

  _exports.default = _default;
});