define("partner-playbook-frontend/templates/components/vendor-details-notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FVFdphV2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[9],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"interfaceForm\"],[9],[0,\"\\n\\t\\t\"],[7,\"form\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"formBoxBorders\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"label\"],[11,\"for\",\"vendorNotes\"],[11,\"class\",\"formLabels\"],[11,\"style\",\"text-decoration:underline;\"],[9],[0,\"Vendor Notes:\"],[10],[7,\"br\"],[9],[10],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"comments\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\"],[7,\"textarea\"],[11,\"id\",\"textarea2\"],[9],[0,\"\\t\\t\\t\\t\\t\\tSupports “ENHANCEDRECEIPTS” API Options for EMV requirements for both US and Canada. Enhanced Receipts are included. Supports Access Token. Manual voids will be handled by server-to-server communication – example, if a customer at the counter wants to cancel their payment at the counter, the clerk will start a void and the interface will submit it server-to-server and not through the UTG. Automatic voids (following the Basic Transaction Flowchart) will be handled by the UTG. Refunds will be handled with tokens. Will be using the same invoice values for partial authorizations. They’ve been advised that using the same invoice number with two different payment sources could cause some settlement issues.\\n\\t\\t\\t\\t\\t\"],[10],[0,\"\\t\\t\\t\\t\"],[10],[0,\"\\n\\t\\t\\t\"],[10],[0,\"\\n\\t\\t\"],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "partner-playbook-frontend/templates/components/vendor-details-notes.hbs"
    }
  });

  _exports.default = _default;
});