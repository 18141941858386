define("partner-playbook-frontend/models/vendor", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    name: attr(),
    channelSalesDirectorId: attr(),
    relationshipManagerId: attr(),
    projectManagerId: attr(),
    website: attr(),
    vendorNotes: attr(),
    inReview: attr(),
    interfaces: attr(),
    projects: attr(),
    isNdaSigned: attr(),
    ndaSigner: attr(),
    ndaDateSigned: attr()
  });

  _exports.default = _default;
});