define("partner-playbook-frontend/templates/components/charter-review-detail-view-section-three", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lZ7gg42A",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"interfaceForm\"],[9],[0,\"\\n    \"],[7,\"form\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"formBoxBorders\"],[9],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "partner-playbook-frontend/templates/components/charter-review-detail-view-section-three.hbs"
    }
  });

  _exports.default = _default;
});