define("partner-playbook-frontend/components/charter-review-detail-view-section-four", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['item-form', 'ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    enableDropdown: true,
    errorMessage: null,
    currentVendor: null,
    actions: {}
  });

  _exports.default = _default;
});