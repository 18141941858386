define("partner-playbook-frontend/components/charter-review-database/charter-review-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    limit: 10,
    offset: 0,
    sortBy: 'name',
    sortAsc: true,
    search: null,
    searchEnabled: true,
    charterReviewHeaders: [{
      name: 'Charter Review Id',
      field: 'id',
      width: 'four',
      sortable: false
    }, {
      name: 'Vendor Name',
      field: 'vendor.name',
      width: 'four',
      sortable: false
    }, {
      name: 'Reviewer',
      field: 'user.lastName',
      width: 'four',
      sortable: false
    }, {
      name: 'Charter Currently In Review',
      field: 'reviewStatus',
      width: 'four',
      sortable: false
    }],
    headers: Ember.computed(function () {
      return this.charterReviewHeaders;
    }),
    actions: {
      handleRowClick: function handleRowClick(aCharterReview) {
        this.handleRowClick(aCharterReview);
      },
      onTermUpdated: function onTermUpdated(search) {
        this.set('search', search);
        this.set('offset', 0);
      },
      filterChanged: function filterChanged(filter) {
        this.filterChanged(filter);
      }
    }
  });

  _exports.default = _default;
});