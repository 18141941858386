define("partner-playbook-frontend/templates/components/ui/search/list-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ap++mz9Y",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"input\",null,[[\"placeholder\",\"value\",\"key-up\",\"insert-newline\"],[[23,[\"placeholder\"]],[23,[\"proxy\"]],[27,\"action\",[[22,0,[]],\"searchTerm\",false],null],[27,\"action\",[[22,0,[]],\"searchTerm\",true],null]]]],false],[0,\"\\n\"],[4,\"if\",[[23,[\"proxy\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"i\"],[11,\"class\",\"times large circle outline link icon\"],[11,\"role\",\"button\"],[9],[3,\"action\",[[22,0,[]],\"clearTerm\"]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[7,\"i\"],[11,\"class\",\"search large link icon\"],[11,\"role\",\"button\"],[9],[3,\"action\",[[22,0,[]],\"searchTerm\",true]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "partner-playbook-frontend/templates/components/ui/search/list-search.hbs"
    }
  });

  _exports.default = _default;
});