define("partner-playbook-frontend/components/contacts-general-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      highlightChange: function highlightChange(record) {
        record.target.style.backgroundColor = "#f08686";
      }
    }
  });

  _exports.default = _default;
});