define("partner-playbook-frontend/components/interface-functions-information4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['item-form', 'ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    enableDropdown: true,
    errorMessage: null,
    actions: {
      toggle4ResSupport: function toggle4ResSupport() {
        this.currentInterface.set('_4Res', !this.currentInterface.get('_4Res'));
      },
      toggle4GoSupport: function toggle4GoSupport() {
        this.currentInterface.set('_4GoSupport', !this.currentInterface.get('_4GoSupport'));
      },
      toggleCheckVerificationSupport: function toggleCheckVerificationSupport() {
        this.currentInterface.set('checkAuthorization', !this.currentInterface.get('checkAuthorization'));
      },
      toggleCredentialsCardOnFileSupport: function toggleCredentialsCardOnFileSupport() {
        this.currentInterface.set('credentialsCardOnFileSupport', !this.currentInterface.get('credentialsCardOnFileSupport'));
      },
      toggleLevel2PurchaseData: function toggleLevel2PurchaseData() {
        this.currentInterface.set('level2PurchaseData', !this.currentInterface.get('level2PurchaseData'));
      },
      togglePurchasingCardData: function togglePurchasingCardData() {
        this.currentInterface.set('purchasingCardData', !this.currentInterface.get('purchasingCardData'));
      },
      toggleAnalyticalTokenSupported: function toggleAnalyticalTokenSupported() {
        this.currentInterface.set('analyticalTokenSupported', !this.currentInterface.get('analyticalTokenSupported'));
      },
      togglePartialAuth: function togglePartialAuth() {
        this.currentInterface.set('partialAuth', !this.currentInterface.get('partialAuth'));
      },
      toggleSplitTender: function toggleSplitTender() {
        this.currentInterface.set('splitTender', !this.currentInterface.get('splitTender'));
      },
      toggleMultiMid: function toggleMultiMid() {
        this.currentInterface.set('multiMid', !this.currentInterface.get('multiMid'));
      },
      toggleExtendedReceipt: function toggleExtendedReceipt() {
        this.currentInterface.set('extendedReceipt', !this.currentInterface.get('extendedReceipt'));
      },
      toggleHtmlInvoice: function toggleHtmlInvoice() {
        this.currentInterface.set('htmlInvoice', !this.currentInterface.get('htmlInvoice'));
      }
    }
  });

  _exports.default = _default;
});