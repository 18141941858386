define("partner-playbook-frontend/components/vendor-details-address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['item-form', 'ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    enableDropdown: true,
    errorMessage: null,
    limit: 10,
    offset: 0,
    sortBy: 'name',
    sortAsc: true,
    search: null,
    searchEnabled: true,
    officeHeaders: [{
      name: 'Office Code',
      field: 'officeCode',
      width: 'four',
      sortable: false
    }, {
      name: 'Street Address',
      field: 'streetAddress',
      width: 'four',
      sortable: false
    }, {
      name: 'City',
      field: 'city',
      width: 'four',
      sortable: false
    }, {
      name: 'State',
      field: 'state',
      width: 'four',
      sortable: false
    }, {
      name: 'Zip Code',
      field: 'zipCode',
      width: 'four',
      sortable: false
    }, {
      name: 'Country',
      field: 'country',
      width: 'four',
      sortable: false
    }],
    vendorOffices: Ember.computed(function () {
      var _this = this;

      return this.interfaceGeneralInfo ? this.get('offices').filter(function (obj) {
        return obj.vendorId == _this.interfaceGeneralInfo.id;
      }) : [];
    }),
    headers: Ember.computed(function () {
      return this.officeHeaders;
    }),
    actions: {
      highlightChange: function highlightChange(record) {
        record.target.style.backgroundColor = "#f08686";
      },
      handleRowClick: function handleRowClick(aOffice) {
        this.handleRowClick(aOffice);
      },
      filterChanged: function filterChanged(filter) {
        this.filterChanged(filter);
      },
      onTermUpdated: function onTermUpdated(search) {
        this.set('search', search);
        this.set('offset', 0);
      }
    }
  });

  _exports.default = _default;
});