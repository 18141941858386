define("partner-playbook-frontend/templates/components/ui/form-fields/checkbox-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wxLUn1HE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[21,\"fieldClasses\"]],[9],[0,\"\\n\\t\"],[7,\"input\"],[11,\"class\",\"hidden\"],[12,\"disabled\",[27,\"if\",[[23,[\"disabled\"]],\"disabled\",null],null]],[12,\"checked\",[27,\"get\",[[23,[\"changeset\"]],[23,[\"property\"]]],null]],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"onChange\"],null]],[12,\"name\",[21,\"name\"]],[12,\"type\",[21,\"type\"]],[9],[10],[0,\"\\n\\t\"],[7,\"label\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onClick\"],null]],[9],[0,\"\\n\\t\\t\"],[1,[21,\"label\"],false],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"showErrorTooltip\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"div\"],[11,\"class\",\"ui inverted red segment error-message\"],[9],[0,\"\\n\\t\\t\"],[1,[27,\"get\",[[27,\"get\",[[23,[\"changeset\",\"error\"]],[23,[\"property\"]]],null],\"validation\"],null],false],[0,\"\\n\\t\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "partner-playbook-frontend/templates/components/ui/form-fields/checkbox-field.hbs"
    }
  });

  _exports.default = _default;
});