define("partner-playbook-frontend/templates/components/ui/dropdown-simple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bwsNuSrt",
    "block": "{\"symbols\":[\"record\"],\"statements\":[[7,\"style\"],[9],[0,\"\\n\\t.dropdown-simple-overflow {\\n\\t\\toverflow: hidden;\\n\\t\\ttext-overflow: ellipsis;\\n\\t\\tmax-width:  maxWidth;\\n\\t}\\n\"],[10],[0,\"\\n\\t\"],[7,\"label\"],[9],[1,[21,\"label\"],false],[10],[0,\"\\n\"],[4,\"ui-dropdown\",null,[[\"selected\",\"onChange\"],[[27,\"get\",[[23,[\"selected\"]],[23,[\"idField\"]]],null],[27,\"action\",[[22,0,[]],\"selectRecord\"],null]]],{\"statements\":[[0,\"\\t\\t\"],[7,\"div\"],[11,\"class\",\"text dropdown-simple-overflow\"],[9],[10],[0,\"\\n\\t\\t\"],[7,\"i\"],[11,\"class\",\"dropdown icon\"],[9],[10],[0,\"\\n\\t\\t\"],[7,\"div\"],[11,\"class\",\"menu\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"ui icon search input\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"i\"],[11,\"class\",\"search icon\"],[9],[10],[0,\"\\n\\t\\t\\t\\t\"],[7,\"input\"],[11,\"placeholder\",\"Search...\"],[11,\"type\",\"text\"],[9],[10],[0,\"\\n\\t\\t\\t\"],[10],[0,\"\\n\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"divider\"],[9],[10],[0,\"\\n\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"scrolling menu\"],[9],[0,\"\\n\\t\\t\\t\\t\\n\"],[4,\"each\",[[23,[\"collection\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"dropdown-simple-item item\"],[12,\"data-value\",[28,[[27,\"get\",[[22,1,[]],[23,[\"idField\"]]],null]]]],[9],[0,\"\\n\\t\\t\\t\\t\"],[1,[27,\"get\",[[22,1,[]],[23,[\"displayField\"]]],null],false],[0,\"\\n\\t\\t\\t\"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\\t\\t\"],[10],[0,\" \\n\\t\\t\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "partner-playbook-frontend/templates/components/ui/dropdown-simple.hbs"
    }
  });

  _exports.default = _default;
});