define("partner-playbook-frontend/templates/components/charter-intake-internal-personnel-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5DwXLD9e",
    "block": "{\"symbols\":[\"@title\"],\"statements\":[[7,\"div\"],[11,\"class\",\"interfaceForm\"],[9],[0,\"\\n\\t\"],[7,\"form\"],[9],[0,\"\\n\\t\\t\"],[7,\"h3\"],[9],[1,[22,1,[]],false],[10],[0,\"\\n\\t\\t\"],[7,\"label\"],[11,\"for\",\"channelSalesDirector\"],[9],[0,\"Channel Sales Director\"],[10],[0,\" \"],[1,[27,\"input\",null,[[\"value\",\"id\",\"class\"],[[23,[\"channelSalesDirector\"]],\"channelSalesDirector\",\"form-control\"]]],false],[7,\"br\"],[9],[10],[0,\"\\n\\t\\t\"],[7,\"label\"],[11,\"for\",\"channelSalesManager\"],[9],[0,\"Channel Sales Manager\"],[10],[0,\" \"],[1,[27,\"input\",null,[[\"value\",\"id\",\"class\"],[[23,[\"channelSalesManager\"]],\"channelSalesManager\",\"form-control\"]]],false],[7,\"br\"],[9],[10],[0,\"\\n\\t\\t\"],[7,\"label\"],[11,\"for\",\"relationshipManager\"],[9],[0,\"Relationship Manager\"],[10],[0,\" \"],[1,[27,\"input\",null,[[\"value\",\"id\",\"class\"],[[23,[\"relationshipManager\"]],\"relationshipManager\",\"form-control\"]]],false],[7,\"br\"],[9],[10],[0,\"\\n\\t\\t\"],[7,\"label\"],[11,\"for\",\"projectManager\"],[9],[0,\"Project Manager\"],[10],[0,\" \"],[1,[27,\"input\",null,[[\"value\",\"id\",\"class\"],[[23,[\"projectManager\"]],\"projectManager\",\"form-control\"]]],false],[7,\"br\"],[9],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "partner-playbook-frontend/templates/components/charter-intake-internal-personnel-details.hbs"
    }
  });

  _exports.default = _default;
});