define("partner-playbook-frontend/models/interface", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    vendorId: belongsTo('vendor', false),
    name: attr(),
    applicationName: attr(),
    version: attr(),
    industry: attr(),
    primaryApiAnalystId: attr(),
    secondaryApiAnalystId: attr(),
    isCertified: attr(),
    certificationDate: attr(),
    reCertificationDate: attr(),
    tokenization: attr(),
    tokenFormat: attr(),
    tokenSharing: attr(),
    tokenSharingMethod: attr(),
    emvSupported: attr(),
    debitSupported: attr(),
    qrPaySupported: attr(),
    ebtSupported: attr(),
    supportedDevice: attr(),
    transactionInputs: attr(),
    deploymentModel: attr(),
    cvv: attr(),
    htmlInvoice: attr(),
    serverToServerSupport: attr(),
    utg4CloudSupport: attr(),
    supportedCardTypes: attr(),
    cardEntry: attr(),
    _4GoSupport: attr(),
    i4GoSupport: attr(),
    i4GoEntryMethod: attr(),
    _4Word: attr(),
    _4Res: attr(),
    checkAuthorization: attr(),
    iycGiftCardFeaturesSupported: attr(),
    privateLabel: attr(),
    metaToken: attr(),
    level2PurchaseData: attr(),
    credentialsCardOnFileSupport: attr(),
    multiMid: attr(),
    selfAttestation: attr(),
    operatingSystems: attr(),
    supportedFrcsEndpoints: attr(),
    specializedApiOptionsSupported: attr(),
    refundSupported: attr(),
    purchaseReturnAuthorizationSupported: attr(),
    communicationMethod: attr(),
    settlementMethod: attr(),
    authenticationMethod: attr(),
    paPciDssDate: attr(),
    extendedReceipt: attr(),
    avsAndCardSecurityCodeHandling: attr(),
    cardSecurityCodeSupport: attr(),
    avsStreetAddress: attr(),
    avsPostalCode: attr(),
    avsCustomerName: attr(),
    voiceReferral: attr(),
    level2CardData: attr(),
    partialAuth: attr(),
    splitTender: attr(),
    listAllDataStored: attr(),
    last4ClientGuid: attr(),
    integrationComments: attr(),
    additionalNotes: attr(),
    inReview: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    vendor: attr(),
    hasTopologyDiagram: attr(),
    isRetail: attr(),
    isFoodAndBeverage: attr(),
    isLodging: attr(),
    isMoto: attr(),
    isECommerce: attr(),
    isOtherIndustryType: attr(),
    certificationCompletionTargetDate: attr(),
    retailServices: attr(),
    motoServices: attr(),
    lodgingServices: attr(),
    eCommerceServices: attr(),
    expectedDeviceFunctionally: attr(),
    useCaseSupport: attr(),
    foodAndBeverageServices: attr(),
    topologyDiagramFilename: attr(),
    charterId: attr()
  });

  _exports.default = _default;
});