define("partner-playbook-frontend/templates/components/charter-intake-contact-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OW8ZrxVB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"nestedInterfaceForm\"],[9],[0,\"\\n\"],[7,\"h3\"],[9],[0,\"@title\"],[10],[0,\"\\n\"],[7,\"label\"],[11,\"for\",\"contactName\"],[9],[0,\"Contact:\"],[10],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"value\"],[[23,[\"contactName\"]]]]],false],[7,\"br\"],[9],[10],[0,\"\\n\\n  \"],[7,\"label\"],[11,\"for\",\"contactTitle\"],[9],[0,\"Contact Title:\"],[10],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"value\"],[[23,[\"contactTitle\"]]]]],false],[7,\"br\"],[9],[10],[0,\"\\n\\n  \"],[7,\"label\"],[11,\"for\",\"contactEmail\"],[9],[0,\"Contact Email:\"],[10],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"value\"],[[23,[\"contactEmail\"]]]]],false],[7,\"br\"],[9],[10],[0,\"\\n\\n  \"],[7,\"label\"],[11,\"for\",\"contactPrimaryPhoneNumber\"],[9],[0,\"Primary Phone:\"],[10],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"value\"],[[23,[\"contactPrimaryPhoneNumber\"]]]]],false],[7,\"br\"],[9],[10],[0,\"\\n\\n  \"],[7,\"label\"],[11,\"for\",\"contactSecondaryPhoneNumber\"],[9],[0,\"Secondary Phone:\"],[10],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"value\"],[[23,[\"contactSecondaryPhoneNumber\"]]]]],false],[7,\"br\"],[9],[10],[0,\"\\n\\n  \"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "partner-playbook-frontend/templates/components/charter-intake-contact-details.hbs"
    }
  });

  _exports.default = _default;
});