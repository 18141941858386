define("partner-playbook-frontend/components/charter-review-detail-view-section-two-a", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['item-form', 'ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    enableDropdown: true,
    errorMessage: null,
    newContact: {},
    inputContact: false,
    newVendorPlaceholder: {
      id: 0,
      name: 'New Vendor'
    },
    isNewVendor: true,
    limit: 10,
    offset: 0,
    sortBy: 'name',
    sortAsc: true,
    search: null,
    searchEnabled: true,
    contactHeaders: [{
      name: 'First Name',
      field: 'firstName',
      width: 'four',
      sortable: false
    }, {
      name: 'Last Name',
      field: 'lastName',
      width: 'four',
      sortable: false
    }, {
      name: 'Primary Phone',
      field: 'primaryPhone',
      width: 'four',
      sortable: false
    }, {
      name: 'Secondary Phone',
      field: 'secondaryPhone',
      width: 'four',
      sortable: false
    }, {
      name: 'Title',
      field: 'title',
      width: 'four',
      sortable: false
    }, {
      name: 'Email',
      field: 'email',
      width: 'four',
      sortable: false
    }, {
      name: 'Role',
      field: 'email',
      width: 'four',
      sortable: false
    }],
    vendorContactRelationsFlat: Ember.computed(function () {
      return this.vendorContactRelationsPlaceholder;
    }),
    vendorContactRelationsPlaceholder: [],
    headers: Ember.computed(function () {
      return this.contactHeaders;
    }),
    channelSalesDirector: Ember.computed(function () {
      var _this = this;

      return this.newCharter.entryData.vendor ? this.model.contacts.find(function (obj) {
        return obj.id == _this.newCharter.entryData.vendor.channelSalesDirectorId;
      }) : null;
    }),
    relationshipManager: Ember.computed(function () {
      var _this2 = this;

      return this.newCharter.entryData.vendor ? this.model.contacts.find(function (obj) {
        return obj.id == _this2.newCharter.entryData.vendor.relationshipManagerId;
      }) : null;
    }),
    projectManager: Ember.computed(function () {
      var _this3 = this;

      return this.newCharter.entryData.vendor ? this.model.contacts.find(function (obj) {
        return obj.id == _this3.newCharter.entryData.vendor.projectManagerId;
      }) : null;
    }),
    actions: {
      onChange: function onChange(value) {
        if (this.onChange) {
          this.onChange(value, this.property);
        } else {
          this.changeset.set(this.property, value);
        }
      },
      changeContact: function changeContact() {//do nothing
      },
      changeVendorSelection: function changeVendorSelection(vendor) {
        this.set('newCharter.entryData.vendor', vendor);
        this.set('isNewVendor', false);
      },
      handleAddAnotherOffice: function handleAddAnotherOffice() {//this.newCharter.set('addAnotherOffice', !this.newCharter.get('addAnotherOffice'));
      },
      toggleIsNdaSigned: function toggleIsNdaSigned() {
        this.newCharter.entryData.vendor.set('isNdaSigned', !this.newCharter.entryData.vendor.get('isNdaSigned'));
      },
      toggleIsDevelopmentVendorDifferent: function toggleIsDevelopmentVendorDifferent() {
        this.newCharter.entryData.vendor.set('isDevelopmentVendorDifferent', !this.newCharter.entryData.vendor.get('isDevelopmentVendorDifferent'));
      },
      ExpandVendorDetails: function ExpandVendorDetails() {// this.set('isNewVendor', true);
      },
      addContactDetails: function addContactDetails() {
        this.vendorContactRelationsPlaceholder.pushObject(this.newContact);
        this.set('newCharter.entryData.vendorContacts', this.vendorContactRelationsPlaceholder);
        this.notifyPropertyChange('vendorContactRelationsFlat');
        this.set('inputContact', false);
      },
      inputContactDetails: function inputContactDetails() {
        this.set('newContact', {});
        this.set('inputContact', true);
      },
      changeProperty: function changeProperty(record, value, property) {
        record[property] = value;
      },
      filterChanged: function filterChanged(filter) {
        this.filterChanged(filter);
      }
    }
  });

  _exports.default = _default;
});