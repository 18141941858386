define("partner-playbook-frontend/models/project", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    ranking: attr(),
    bdRanking: attr(),
    vendorId: belongsTo('vendor', false),
    projectName: attr(),
    stage: attr(),
    certifiedDate: attr(),
    projectStatus: attr(),
    type: attr(),
    apiPocId: attr(),
    bdPocId: attr(),
    primaryPsPOCId: attr(),
    secondaryPsPOCId: attr(),
    startDate: attr(),
    lastUpdate: attr(),
    nextUpdate: attr(),
    apiComments: attr(),
    pilotComments: attr(),
    jiraTicket: attr(),
    bdComments: attr(),
    newVendor: attr(),
    csmRsmPmPocId: attr(),
    trainee: attr(),
    InstallationPocId: attr(),
    vendorPocId: attr(),
    pilotSiteName: attr(),
    pilotSitePocId: attr(),
    address: attr(),
    dateCompleted: attr(),
    inReview: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    vendor: attr(),
    hasProjectDeadline: attr(),
    hasMerchantAssociation: attr(),
    projectDeadlineDetails: attr(),
    cardTokenUsage: attr(),
    previousPaymentServices: attr(),
    charterId: attr()
  });

  _exports.default = _default;
});