define("partner-playbook-frontend/components/interface-functions-information3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['item-form', 'ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    enableDropdown: true,
    errorMessage: null,
    isCardEntry: false,
    i4GoSupportedSelectionList: [{
      id: 'No'
    }, {
      id: 'iFrame'
    }, {
      id: 'Direct Post via redirect'
    }, {
      id: 'Direct Post via AJAX'
    }, {
      id: 'QR Pay'
    }],
    i4GoEntryMethodSelectionList: [{
      id: 'No'
    }, {
      id: 'iFrame'
    }, {
      id: 'Direct Post via redirect'
    }, {
      id: 'Direct Post via AJAX'
    }, {
      id: 'QR Pay'
    }],
    actions: {
      changeI4GoSupportedSelection: function changeI4GoSupportedSelection() {//do nothing
      },
      changeI4GoEntryMethodSelection: function changeI4GoEntryMethodSelection() {//do nothing
      },
      highlightChange: function highlightChange(record) {
        record.target.style.backgroundColor = "#f08686";
      },
      showCardEntry: function showCardEntry() {
        this.set('isCardEntry', true);
      },
      hideCardEntry: function hideCardEntry() {
        this.set('isCardEntry', false);
      },
      toggleUnEncryptedMsr: function toggleUnEncryptedMsr() {
        this.currentInterface.set('unEncryptedMsr', !this.currentInterface.get('unEncryptedMsr'));
      },
      toggleUnEncryptedCard: function toggleUnEncryptedCard() {
        this.currentInterface.set('unEncryptedCard', !this.currentInterface.get('unEncryptedCard'));
      },
      toggleMicr: function toggleMicr() {
        this.currentInterface.set('micr', !this.currentInterface.get('micr'));
      },
      toggleNonMicr: function toggleNonMicr() {
        this.currentInterface.set('nonMicr', !this.currentInterface.get('nonMicr'));
      },
      toggleToken: function toggleToken() {
        this.currentInterface.set('token', !this.currentInterface.get('token'));
      },
      toggleNativeP2pe: function toggleNativeP2pe() {
        this.currentInterface.set('nativeP2pe', !this.currentInterface.get('nativeP2pe'));
      },
      toggleUtgControlledPinPad: function toggleUtgControlledPinPad() {
        this.currentInterface.set('utgControlledPinPad', !this.currentInterface.get('utgControlledPinPad'));
      },
      toggleNativeEmv: function toggleNativeEmv() {
        this.currentInterface.set('nativeEmv', !this.currentInterface.get('nativeEmv'));
      },
      toggleRefundSupported: function toggleRefundSupported() {
        this.currentInterface.set('refundSupported', !this.currentInterface.get('refundSupported'));
      },
      togglePurchaseReturnAuthSupported: function togglePurchaseReturnAuthSupported() {
        this.currentInterface.set('purchaseReturnAuthSupported', !this.currentInterface.get('purchaseReturnAuthSupported'));
      },
      toggleCardSecurityHandling: function toggleCardSecurityHandling() {
        this.currentInterface.set('cardSecurityHandling', !this.currentInterface.get('cardSecurityHandling'));
      },
      toggleCardSecurityCodeSupported: function toggleCardSecurityCodeSupported() {
        this.currentInterface.set('cardSecurityCodeSupported', !this.currentInterface.get('cardSecurityCodeSupported'));
      }
    }
  });

  _exports.default = _default;
});