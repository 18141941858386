define("partner-playbook-frontend/controllers/vendor-database", ["exports", "partner-playbook-frontend/controllers/general-list-controller"], function (_exports, _generalListController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalListController.default.extend({
    queryParams: ['search', 'offset', 'limit', 'order', 'sort'],
    order: 'id',
    currentVendor: null,
    currentProject: null,
    currentInterface: null,
    isVendorExpanded: false,
    isInterfaceExpanded: false,
    isProjectExpanded: false,
    isVendorTableCollapsed: false,
    showErrors: null,
    flashMessages: null,
    list: null,
    vendorOffices: Ember.computed(function () {
      var _this = this;

      return this.currentVendor ? this.get('offices').filter(function (obj) {
        return obj.vendorId == _this.currentVendor.id;
      }) : [];
    }),
    vendorList: function vendorList() {
      this.set('isVendorTableCollapsed', false);
      this.set('isVendorExpanded', false);
      this.set('isInterfaceExpanded', false);
      this.set('isProjectExpanded', false);
      this.set('currentVendor', null);
      this.set('currentInterface', null);
      this.set('currentProject', null);
    },
    projectList_InterfaceList: function projectList_InterfaceList() {
      this.set('isVendorTableCollapsed', true);
      this.set('isVendorExpanded', true);
      this.set('isInterfaceExpanded', false);
      this.set('isProjectExpanded', false);
      this.set('currentInterface', null);
      this.set('currentProject', null);
    },
    actions: {
      changeContact: function changeContact() {},
      saveVendorChanges: function saveVendorChanges() {
        this.currentVendor.save();
        this.vendorList();
      },
      saveInterfaceChanges: function saveInterfaceChanges() {
        this.currentInterface.save();
        this.projectList_interfaceList();
      },
      saveProjectChanges: function saveProjectChanges() {
        this.currentProject.save();
        this.projectList_interfaceList();
      },
      vendorList: function vendorList() {
        this.vendorList();
      },
      chosenVendor: function chosenVendor() {
        this.set('isVendorTableCollapsed', true);
        this.set('isVendorExpanded', true);
        this.set('isInterfaceExpanded', false);
        this.set('isProjectExpanded', false);
        this.set('currentInterface', null);
        this.set('currentProject', null);
      },
      projectList_interfaceList: function projectList_interfaceList() {
        this.projectList_interfaceList();
      },
      onTermUpdated: function onTermUpdated(search) {
        this.set('search', search);
        this.set('offset', 0);
      },
      filterVendorChanged: function filterVendorChanged(filter) {
        var limit = filter.limit,
            offset = filter.offset,
            sortAsc = filter.sortAsc,
            order = filter.sortBy;
        var sort = sortAsc ? 'asc' : 'desc';
        this.setProperties({
          limit: limit,
          offset: offset,
          sort: sort,
          order: order
        });
      },
      onVendorRowClick: function onVendorRowClick(aVendor) {
        if (!this.isVendorExpanded) {
          this.toggleProperty('isVendorExpanded');
        }

        if (!this.isVendorTableCollapsed) {
          this.toggleProperty('isVendorTableCollapsed');
        }

        if (this.isInterfaceExpanded) {
          this.toggleProperty('isInterfaceExpanded');
        }

        if (this.isProjectExpanded) {
          this.toggleProperty('isProjectExpanded');
        }

        this.set('currentVendor', aVendor);
      },
      onInterfaceRowClick: function onInterfaceRowClick(anInterface) {
        if (!this.isVendorTableCollapsed) {
          this.toggleProperty('isVendorTableCollapsed');
        }

        if (this.isVendorExpanded) {
          this.toggleProperty('isVendorExpanded');
        }

        if (!this.isInterfaceExpanded) {
          this.toggleProperty('isInterfaceExpanded');
        }

        if (this.isProjectExpanded) {
          this.toggleProperty('isProjectExpanded');
        }

        this.set('currentInterface', this.store.findRecord('interface', anInterface.id));
      },
      onProjectRowClick: function onProjectRowClick(aProject) {
        if (!this.isVendorTableCollapsed) {
          this.toggleProperty('isVendorTableCollapsed');
        }

        if (this.isVendorExpanded) {
          this.toggleProperty('isVendorExpanded');
        }

        if (this.isInterfaceExpanded) {
          this.toggleProperty('isInterfaceExpanded');
        }

        if (!this.isProjectExpanded) {
          this.toggleProperty('isProjectExpanded');
        }

        this.set('currentProject', this.store.findRecord('project', aProject.id));
      }
    }
  });

  _exports.default = _default;
});