define("partner-playbook-frontend/components/project-details-pilots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['item-form', 'ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    enableDropdown: true,
    errorMessage: null,
    currentProject: null,
    newContact: {},
    newVendorOffice: {},
    inputContact: false,
    inputVendor: false,
    newVendorPlaceholder: {
      id: 0,
      name: 'New Vendor'
    },
    isNewVendor: true,
    limit: 10,
    offset: 0,
    sortBy: 'name',
    sortAsc: true,
    search: null,
    searchEnabled: true,
    officeEntryDataFormatted: Ember.computed(function () {
      return "Office: ".concat(JSON.stringify(this.newCharter.entryData.office, null, '\t'));
    }),
    vendorEntryDataFormatted: Ember.computed(function () {
      //alert('reading vendorEntryDataFormatted');
      console.log("Vendor: ".concat(JSON.stringify(this.newCharter.entryData.vendor, null, '\t')));
      return "Vendor: ".concat(JSON.stringify(this.newCharter.entryData.vendor, null, '\t'));
    }),
    contactHeaders: [{
      name: 'First Name',
      field: 'firstName',
      width: 'four',
      sortable: false
    }, {
      name: 'Last Name',
      field: 'lastName',
      width: 'four',
      sortable: false
    }, {
      name: 'Primary Phone',
      field: 'primaryPhone',
      width: 'four',
      sortable: false
    }, {
      name: 'Secondary Phone',
      field: 'secondaryPhone',
      width: 'four',
      sortable: false
    }, {
      name: 'Title',
      field: 'title',
      width: 'four',
      sortable: false
    }, {
      name: 'Email',
      field: 'email',
      width: 'four',
      sortable: false
    }, {
      name: 'Role',
      field: 'email',
      width: 'four',
      sortable: false
    }],
    vendorContactRelationsFlat: Ember.computed(function () {
      return this.vendorContactRelationsPlaceholder;
    }),
    vendorContactRelationsPlaceholder: [],
    headers: Ember.computed(function () {
      return this.contactHeaders;
    }),
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      highlightChange: function highlightChange(record) {
        record.target.style.backgroundColor = "#f08686";
      },
      newPilot: function newPilot() {
        alert('New Pilot Requested');
      }
    }
  });

  _exports.default = _default;
});