define("partner-playbook-frontend/templates/components/ui/form-fields/static-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "onIPxvCY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[21,\"fieldClasses\"]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"label\"]]],null,{\"statements\":[[4,\"ui/form-fields/shared/label\",null,[[\"fullWidth\",\"widthClass\"],[[23,[\"fullWidth\"]],[23,[\"labelWidth\"]]]],{\"statements\":[[1,[21,\"label\"],false]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\t\"],[7,\"div\"],[12,\"class\",[28,[[21,\"inputClass\"]]]],[9],[0,\"\\n\\t\\t\"],[14,1],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "partner-playbook-frontend/templates/components/ui/form-fields/static-field.hbs"
    }
  });

  _exports.default = _default;
});