define("partner-playbook-frontend/templates/components/charter-intake-address-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "93qxSaWQ",
    "block": "{\"symbols\":[\"@title\"],\"statements\":[[7,\"div\"],[11,\"class\",\"nestedInterfaceForm\"],[9],[0,\"\\n\\t\"],[7,\"h3\"],[9],[1,[22,1,[]],false],[10],[0,\"\\n\\n\\t\"],[7,\"label\"],[11,\"for\",\"officeAddress\"],[9],[0,\"Office Address:\"],[10],[0,\"\\n\\t\"],[1,[27,\"input\",null,[[\"value\"],[[23,[\"officeAddress\"]]]]],false],[7,\"br\"],[9],[10],[0,\"\\n\\n\\t\"],[7,\"label\"],[11,\"for\",\"city\"],[9],[0,\"City:\"],[10],[0,\"\\n\\t\"],[1,[27,\"input\",null,[[\"value\"],[[23,[\"city\"]]]]],false],[7,\"br\"],[9],[10],[0,\"\\n\\n\\t\"],[7,\"label\"],[11,\"for\",\"state\"],[9],[0,\"State:\"],[10],[0,\"\\n\\t\"],[1,[27,\"input\",null,[[\"value\"],[[23,[\"state\"]]]]],false],[7,\"br\"],[9],[10],[0,\"\\n\\n\\t\"],[7,\"label\"],[11,\"for\",\"zipCode\"],[9],[0,\"Zip Code:\"],[10],[0,\"\\n\\t\"],[1,[27,\"input\",null,[[\"value\"],[[23,[\"zipCode\"]]]]],false],[7,\"br\"],[9],[10],[0,\"\\n\\n\\t\"],[7,\"label\"],[11,\"for\",\"country\"],[9],[0,\"Country:\"],[10],[0,\"\\n\\t\"],[1,[27,\"input\",null,[[\"value\"],[[23,[\"country\"]]]]],false],[7,\"br\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "partner-playbook-frontend/templates/components/charter-intake-address-details.hbs"
    }
  });

  _exports.default = _default;
});