define("partner-playbook-frontend/components/ui/form-fields/checkbox-field", ["exports", "partner-playbook-frontend/components/ui/form-fields/field"], function (_exports, _field) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _field.default.extend({
    disabled: false,
    type: 'checkbox',
    isHeader: false,
    isToggle: false,
    dataTest: 'form-field-checkbox-field',
    init: function init() {
      this._super.apply(this, arguments);

      var changeset = this.changeset,
          property = this.property;

      if (!changeset) {
        return;
      }

      var propertyString = "changeset.".concat(property, ".[]");
      Ember.defineProperty(this, 'isChecked', Ember.computed(propertyString, function () {
        return changeset.get(property);
      }));
    },
    fieldClasses: Ember.computed('isChecked', 'showErrorTooltip', 'changeset.error', function () {
      var hasError = this.changeset && this.property ? this.changeset.get("error.".concat(this.property)) : false;
      var stateClasses = ["".concat(this.disabled ? ' disabled' : ''), "".concat(this.hasFocus ? ' focused' : ''), "".concat(this.showErrorTooltip ? ' show-error-tooltip' : ''), "".concat(hasError ? ' error' : ''), "".concat(this.isChecked ? ' checked' : ''), "".concat(this.isToggle ? ' toggle' : ''), "".concat(this.isHeader ? '' : this.isToggle ? ' vertical-toggle' : ' vertical-checkbox')];
      return "ui checkbox".concat(stateClasses.join(''));
    }),
    actions: {
      onClick: function onClick() {
        if (this.onChange) {
          this.onChange(this.isChecked);
        } else {
          this.changeset.set(this.property, !this.isChecked);
        }
      }
    }
  });

  _exports.default = _default;
});