define("partner-playbook-frontend/templates/components/ui/client-pagination-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hnX1csqh",
    "block": "{\"symbols\":[\"page\"],\"statements\":[[7,\"div\"],[11,\"class\",\"ui right floated pagination menu client-pagination-buttons-comp\"],[9],[0,\"\\n\\t\"],[7,\"a\"],[12,\"class\",[28,[\"pagination-previous-btn icon relative item \",[27,\"if\",[[23,[\"disablePrevious\"]],\"disabled\"],null]]]],[11,\"role\",\"button\"],[9],[0,\"\\n\\t\\t\"],[7,\"i\"],[11,\"class\",\"left chevron icon\"],[9],[10],[0,\"\\n\\t\"],[3,\"action\",[[22,0,[]],\"pageDecrement\"]],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"pageNumbers\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"a\"],[12,\"class\",[28,[\"pagination-page-btn item \",[27,\"if\",[[27,\"eq\",[[22,1,[]],[23,[\"currentPage\"]]],null],\"active\"],null],\" \",[27,\"if\",[[27,\"eq\",[[22,1,[]],\"...\"],null],\"disabled\"],null]]]],[11,\"role\",\"button\"],[9],[0,\"\\n\\t\\t\\t\"],[1,[22,1,[]],false],[0,\"\\n\\t\\t\"],[3,\"action\",[[22,0,[]],\"pageChanged\",[22,1,[]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\t\"],[7,\"a\"],[12,\"class\",[28,[\"pagination-next-btn icon relative item \",[27,\"if\",[[23,[\"disableNext\"]],\"disabled\"],null]]]],[11,\"role\",\"button\"],[9],[0,\"\\n\\t\\t\"],[7,\"i\"],[11,\"class\",\"right chevron icon\"],[9],[10],[0,\"\\n\\t\"],[3,\"action\",[[22,0,[]],\"pageIncrement\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "partner-playbook-frontend/templates/components/ui/client-pagination-buttons.hbs"
    }
  });

  _exports.default = _default;
});