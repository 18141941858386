define("partner-playbook-frontend/router", ["exports", "partner-playbook-frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('vendors');
    this.route('contacts');
    this.route('interfaces');
    this.route('projects');
    this.route('charter-intake');
    this.route('charter-review');
    this.route('admin');
    this.route('interface-database');
    this.route('vendor-database');
    this.route('contact-database');
    this.route('project-database');
    this.route('release-notes');
    this.route('charter-review-database');
  });
  var _default = Router;
  _exports.default = _default;
});