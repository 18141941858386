define("partner-playbook-frontend/components/interface-functions-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['item-form', 'ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    enableDropdown: true,
    errorMessage: null,
    isCommMethod: false,
    isS2SSupported: false,
    isUtg4Cloud: false,
    isDeploymentMethod: false,
    isSettlementMethod: false,
    isDeviceMethod: false,
    isTokenMethod: false,
    tokenizationFormatSelectionList: [{
      id: 'N/A'
    }, {
      id: 'Shift4'
    }, {
      id: 'Global Token Vault'
    }],
    actions: {
      showCommMethod: function showCommMethod() {
        this.set('isCommMethod', true);
      },
      hideCommMethod: function hideCommMethod() {
        this.set('isCommMethod', false);
      },
      toggleCommMethodHttp: function toggleCommMethodHttp() {
        this.currentInterface.set('commMethodHttp', !this.currentInterface.get('commMethodHttp'));
      },
      toggleCommMethodHttps: function toggleCommMethodHttps() {
        this.currentInterface.set('commMethodHttps', !this.currentInterface.get('commMethodHttps'));
      },
      toggleCommMethodTcpIp: function toggleCommMethodTcpIp() {
        this.currentInterface.set('commMethodTcpIp', !this.currentInterface.get('commMethodTcpIp'));
      },
      toggleCommMethodRestAPI: function toggleCommMethodRestAPI() {
        this.currentInterface.set('commMethodRestAPI', !this.currentInterface.get('commMethodRestAPI'));
      },
      showS2SSupported: function showS2SSupported() {
        this.set('isS2SSupported', true);
      },
      hideS2SSupported: function hideS2SSupported() {
        this.set('isS2SSupported', false);
      },
      toggleS2SSupportedNone: function toggleS2SSupportedNone() {
        this.currentInterface.set('s2SSupportedNone', !this.currentInterface.get('s2SSupportedNone'));
      },
      toggleS2SSupportedCfApi: function toggleS2SSupportedCfApi() {
        this.currentInterface.set('s2SSupportedCfApi', !this.currentInterface.get('s2SSupportedCfApi'));
      },
      toggleS2SSupportedRest: function toggleS2SSupportedRest() {
        this.currentInterface.set('s2SSupportedRest', !this.currentInterface.get('s2SSupportedRest'));
      },
      showUtg4Cloud: function showUtg4Cloud() {
        this.set('isUtg4Cloud', true);
      },
      hideUtg4Cloud: function hideUtg4Cloud() {
        this.set('isUtg4Cloud', false);
      },
      toggleUtg4CloudNone: function toggleUtg4CloudNone() {
        this.currentInterface.set('utg4CloudNone', !this.currentInterface.get('utg4CloudNone'));
      },
      toggleUtg4CloudHttp: function toggleUtg4CloudHttp() {
        this.currentInterface.set('utg4CloudHttp', !this.currentInterface.get('utg4CloudHttp'));
      },
      toggleUtg4CloudTcpip: function toggleUtg4CloudTcpip() {
        this.currentInterface.set('utg4CloudTcpIp', !this.currentInterface.get('utg4CloudTcpIp'));
      },
      toggleUtg4CloudHttps: function toggleUtg4CloudHttps() {
        this.currentInterface.set('utg4CloudHttps', !this.currentInterface.get('utg4CloudHttps'));
      },
      showDeploymentMethod: function showDeploymentMethod() {
        this.set('isDeploymentMethod', true);
      },
      hideDeploymentMethod: function hideDeploymentMethod() {
        this.set('isDeploymentMethod', false);
      },
      toggleDeploymentMethodA: function toggleDeploymentMethodA() {
        this.currentInterface.set('methodA', !this.currentInterface.get('methodA'));
      },
      toggleDeploymentMethodB: function toggleDeploymentMethodB() {
        this.currentInterface.set('methodB', !this.currentInterface.get('methodB'));
      },
      toggleDeploymentMethodC: function toggleDeploymentMethodC() {
        this.currentInterface.set('methodC', !this.currentInterface.get('methodC'));
      },
      toggleDeploymentMethodD: function toggleDeploymentMethodD() {
        this.currentInterface.set('methodD', !this.currentInterface.get('methodD'));
      },
      showSettlementMethod: function showSettlementMethod() {
        this.set('isSettlementMethod', true);
      },
      hideSettlementMethod: function hideSettlementMethod() {
        this.set('isSettlementMethod', false);
      },
      toggleSettlementMethodAuthCapture: function toggleSettlementMethodAuthCapture() {
        this.currentInterface.set('authCapture', !this.currentInterface.get('authCapture'));
      },
      toggleSettlementMethodStraightCapture: function toggleSettlementMethodStraightCapture() {
        this.currentInterface.set('straightCapture', !this.currentInterface.get('straightCapture'));
      },
      toggleSettlementMethodIncrementalAuth: function toggleSettlementMethodIncrementalAuth() {
        this.currentInterface.set('incrementalAuth', !this.currentInterface.get('incrementalAuth'));
      },
      toggleSettlementMethodSaleCapture: function toggleSettlementMethodSaleCapture() {
        this.currentInterface.set('saleCapture', !this.currentInterface.get('saleCapture'));
      },
      showDeviceMethod: function showDeviceMethod() {
        this.set('isDeviceMethod', true);
      },
      hideDeviceMethod: function hideDeviceMethod() {
        this.set('isDeviceMethod', false);
      },
      toggleDeviceMethodNone: function toggleDeviceMethodNone() {
        this.currentInterface.set('none', !this.currentInterface.get('none'));
      },
      toggleDeviceMethodUtgControlledDevices: function toggleDeviceMethodUtgControlledDevices() {
        this.currentInterface.set('utgControlledDevices', !this.currentInterface.get('utgControlledDevices'));
      },
      toggleDeviceMethodIdTechDevicesHid: function toggleDeviceMethodIdTechDevicesHid() {
        this.currentInterface.set('idTechDevicesHid', !this.currentInterface.get('idTechDevicesHid'));
      },
      toggleDeviceMethodIdTechDevicesKb: function toggleDeviceMethodIdTechDevicesKb() {
        this.currentInterface.set('idTechDevicesKb', !this.currentInterface.get('idTechDevicesKb'));
      },
      toggleDeviceMethodInnowiDevices: function toggleDeviceMethodInnowiDevices() {
        this.currentInterface.set('innowiDevices', !this.currentInterface.get('innowiDevices'));
      },
      toggleDeviceMethodIngenicoNonUtgControlledDevices: function toggleDeviceMethodIngenicoNonUtgControlledDevices() {
        this.currentInterface.set('ingenicoNonUtgControlledDevices', !this.currentInterface.get('ingenicoNonUtgControlledDevices'));
      },
      toggleDeviceMethodVerifoneNonUtgControlledDevices: function toggleDeviceMethodVerifoneNonUtgControlledDevices() {
        this.currentInterface.set('verifoneNonUtgControlledDevices', !this.currentInterface.get('verifoneNonUtgControlledDevices'));
      },
      toggleDeviceMethodNonEmvIdTechDevices: function toggleDeviceMethodNonEmvIdTechDevices() {
        this.currentInterface.set('nonEmvIdTechDevices', !this.currentInterface.get('nonEmvIdTechDevices'));
      },
      toggleTokenization: function toggleTokenization() {
        this.currentInterface.set('tokenization', !this.currentInterface.get('tokenization'));
      },
      showTokenMethod: function showTokenMethod() {
        this.set('isTokenMethod', true);
      },
      hideTokenMethod: function hideTokenMethod() {
        this.set('isTokenMethod', false);
      },
      toggleTokenMethodNone: function toggleTokenMethodNone() {
        this.currentInterface.set('none', !this.currentInterface.get('none'));
      },
      toggleTokenMethodTokenShare: function toggleTokenMethodTokenShare() {
        this.currentInterface.set('tokenShare', !this.currentInterface.get('tokenShare'));
      },
      toggleTokenMethodGlobalToken: function toggleTokenMethodGlobalToken() {
        this.currentInterface.set('globalToken', !this.currentInterface.get('globalToken'));
      },
      toggleTokenMethodGlobalTokenVaultGtv: function toggleTokenMethodGlobalTokenVaultGtv() {
        this.currentInterface.set('globalTokenVaultGtv', !this.currentInterface.get('globalTokenVaultGtv'));
      },
      toggleEmvSupported: function toggleEmvSupported() {
        this.currentInterface.set('emvSupported', !this.currentInterface.get('emvSupported'));
      },
      toggleDebitSupported: function toggleDebitSupported() {
        this.currentInterface.set('debitSupported', !this.currentInterface.get('debitSupported'));
      },
      toggleQrPaySupported: function toggleQrPaySupported() {
        this.currentInterface.set('qrPaySupported', !this.currentInterface.get('qrPaySupported'));
      },
      toggleEbtSupported: function toggleEbtSupported() {
        this.currentInterface.set('ebtSupported', !this.currentInterface.get('ebtSupported'));
      },
      changeTokenizationFormat: function changeTokenizationFormat() {}
    }
  });

  _exports.default = _default;
});