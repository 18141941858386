define("partner-playbook-frontend/templates/components/tables/responsive-table/mobile-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VTWfmuXG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[27,\"and\",[[23,[\"headerName\"]],[27,\"not\",[[23,[\"mobileHeaderHidden\"]]],null]],null]],null,{\"statements\":[[7,\"dt\"],[9],[1,[21,\"headerName\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"cellClass\"]]],null,{\"statements\":[[7,\"dd\"],[12,\"class\",[28,[[21,\"cellClass\"]]]],[9],[14,1],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[7,\"dd\"],[9],[14,1],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "partner-playbook-frontend/templates/components/tables/responsive-table/mobile-cell.hbs"
    }
  });

  _exports.default = _default;
});