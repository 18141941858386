define("partner-playbook-frontend/templates/contact-database", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r6f6F5v6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"contacts-database\"],[9],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"view\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isExpanded\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"button\"],[11,\"class\",\"formButtons\"],[9],[0,\"Hide Contact Preview\"],[3,\"action\",[[22,0,[]],\"collapsePreview\"]],[10],[7,\"br\"],[9],[10],[0,\"\\n\\t\\t\"],[7,\"div\"],[11,\"class\",\"contactDetailsView\"],[11,\"style\",\"display: flex;\"],[9],[0,\"\\n\\t\\t\\t\"],[5,\"contacts-general-information\",[],[[\"@title\",\"@currentContact\"],[\"Contacts General Information\",[21,\"currentContact\"]]]],[0,\"\\n\\t\\t\"],[10],[0,\"\\n\\t\\t\"],[7,\"button\"],[9],[0,\"Save Contact Changes >\"],[3,\"action\",[[22,0,[]],\"saveContactChanges\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\t\"],[10],[0,\"\\n\\n\\t\"],[7,\"div\"],[11,\"class\",\"items\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\"],[11,\"class\",\"item\"],[9],[0,\"\\n\\t\\t\\t\"],[2,\"\\t{{ui/search/list-search\\n\\t\\t\\t\\tsearchTerm=search\\n\\t\\t\\t\\tplaceholder=\\\"Search by Name\\\"\\n\\t\\t\\t\\tonUpdated=(action 'onTermUpdated')\\n\\t\\t\\t}}\"],[0,\"\\n\\t\\t\"],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\\n\\t\"],[1,[27,\"contact-database/contact-table\",null,[[\"limit\",\"offset\",\"sortBy\",\"sortAsc\",\"search\",\"contacts\",\"filterChanged\",\"handleRowClick\"],[[23,[\"limit\"]],[23,[\"offset\"]],[23,[\"order\"]],true,[23,[\"search\"]],[23,[\"model\",\"contacts\"]],[27,\"action\",[[22,0,[]],\"filterChanged\"],null],[27,\"action\",[[22,0,[]],\"onRowClick\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "partner-playbook-frontend/templates/contact-database.hbs"
    }
  });

  _exports.default = _default;
});