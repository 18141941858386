define("partner-playbook-frontend/models/charter", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    createdAt: attr(),
    updatedAt: attr(),
    vendorId: attr(),
    vendor: attr(),
    vendorName: attr(),
    userId: attr(),
    user: attr(),
    reviewStatus: attr(),
    inReview: attr(),
    isDevelopmentVendorDifferent: attr(),
    developmentVendorName: attr(),
    entryData: attr()
  });

  _exports.default = _default;
});