define("partner-playbook-frontend/components/interface-functions-information2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['item-form', 'ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    enableDropdown: true,
    errorMessage: null,
    isEndpointsFunctionsSupported: false,
    isIycGcFunctionsSupported: false,
    isPrivateLabelFunctionsSupported: false,
    isSpecializeApiOptions: false,
    actions: {
      showEndpointsFunctionsSupported: function showEndpointsFunctionsSupported() {
        this.set('isEndpointsFunctionsSupported', true);
      },
      hideEndpointsFunctionsSupported: function hideEndpointsFunctionsSupported() {
        this.set('isEndpointsFunctionsSupported', false);
      },
      toggleAuthorization: function toggleAuthorization() {
        this.currentInterface.set('authorization', !this.currentInterface.get('authorization'));
      },
      toggleSalePurchase: function toggleSalePurchase() {
        this.currentInterface.set('salePurchase', !this.currentInterface.get('salePurchase'));
      },
      toggleFrc1B: function toggleFrc1B() {
        this.currentInterface.set('frc1B', !this.currentInterface.get('frc1B'));
      },
      toggleFrc1D: function toggleFrc1D() {
        this.currentInterface.set('frc1D', !this.currentInterface.get('frc1D'));
      },
      showIycGcFunctionsSupported: function showIycGcFunctionsSupported() {
        this.set('isIycGcFunctionsSupported', true);
      },
      hideIycGcFunctionsSupported: function hideIycGcFunctionsSupported() {
        this.set('isIycGcFunctionsSupported', false);
      },
      toggleFRC24: function toggleFRC24() {
        this.currentInterface.set('frc24', !this.currentInterface.get('frc24'));
      },
      toggleFRC25: function toggleFRC25() {
        this.currentInterface.set('frc25', !this.currentInterface.get('frc25'));
      },
      toggleActivateFrc1B: function toggleActivateFrc1B() {
        this.currentInterface.set('activateFrc1B', !this.currentInterface.get('activateFrc1B'));
      },
      toggleDeactivate: function toggleDeactivate() {
        this.currentInterface.set('deactivate', !this.currentInterface.get('deactivate'));
      },
      showPrivateLabelFunctionsSupported: function showPrivateLabelFunctionsSupported() {
        this.set('isPrivateLabelFunctionsSupported', true);
      },
      hidePrivateLabelFunctionsSupported: function hidePrivateLabelFunctionsSupported() {
        this.set('isPrivateLabelFunctionsSupported', false);
      },
      toggleMethodE: function toggleMethodE() {
        this.currentInterface.set('methodE', !this.currentInterface.get('methodE'));
      },
      toggleMethodF: function toggleMethodF() {
        this.currentInterface.set('methodF', !this.currentInterface.get('methodF'));
      },
      toggleMethodG: function toggleMethodG() {
        this.currentInterface.set('methodG', !this.currentInterface.get('methodG'));
      },
      toggleMethodH: function toggleMethodH() {
        this.currentInterface.set('methodH', !this.currentInterface.get('methodH'));
      },
      showSpecializeApiOptions: function showSpecializeApiOptions() {
        this.set('isSpecializeApiOptions', true);
      },
      hideSpecializeApiOptions: function hideSpecializeApiOptions() {
        this.set('isSpecializeApiOptions', false);
      },
      toggleReturnExpDate: function toggleReturnExpDate() {
        this.currentInterface.set('returnExpDate', !this.currentInterface.get('returnExpDate'));
      },
      toggleUseCardName: function toggleUseCardName() {
        this.currentInterface.set('useCardName', !this.currentInterface.get('useCardName'));
      },
      toggleAllowCashBack: function toggleAllowCashBack() {
        this.currentInterface.set('allowCashBack', !this.currentInterface.get('allowCashBack'));
      },
      toggleBypassAmountOk: function toggleBypassAmountOk() {
        this.currentInterface.set('bypassAmountOk', !this.currentInterface.get('bypassAmountOk'));
      },
      toggleBypassIgCap: function toggleBypassIgCap() {
        this.currentInterface.set('bypassIgCap', !this.currentInterface.get('bypassIgCap'));
      },
      toggleBypassUtg: function toggleBypassUtg() {
        this.currentInterface.set('bypassUtg', !this.currentInterface.get('bypassUtg'));
      },
      toggleBypassTip: function toggleBypassTip() {
        this.currentInterface.set('bypassTip', !this.currentInterface.get('bypassTip'));
      },
      toggleDisableEmv: function toggleDisableEmv() {
        this.currentInterface.set('disableEmv', !this.currentInterface.get('disableEmv'));
      },
      toggleDisableMce: function toggleDisableMce() {
        this.currentInterface.set('disableMce', !this.currentInterface.get('disableMce'));
      },
      toggleDisableContactless: function toggleDisableContactless() {
        this.currentInterface.set('disableContactless', !this.currentInterface.get('disableContactless'));
      },
      toggleDisableTrackInfo: function toggleDisableTrackInfo() {
        this.currentInterface.set('disableTrackInfo', !this.currentInterface.get('disableTrackInfo'));
      },
      toggleLaneClosed: function toggleLaneClosed() {
        this.currentInterface.set('laneClosed', !this.currentInterface.get('laneClosed'));
      },
      toggleNoSignature: function toggleNoSignature() {
        this.currentInterface.set('noSignature', !this.currentInterface.get('noSignature'));
      },
      togglePrintTipLine: function togglePrintTipLine() {
        this.currentInterface.set('printTipLine', !this.currentInterface.get('printTipLine'));
      },
      toggleReturnSignature: function toggleReturnSignature() {
        this.currentInterface.set('returnSignature', !this.currentInterface.get('returnSignature'));
      },
      toggleUseMce: function toggleUseMce() {
        this.currentInterface.set('useMce', !this.currentInterface.get('useMce'));
      },
      toggleTokenAuth: function toggleTokenAuth() {
        this.currentInterface.set('tokenAuth', !this.currentInterface.get('tokenAuth'));
      },
      toggleNonProblemsOnly: function toggleNonProblemsOnly() {
        this.currentInterface.set('nonProblemsOnly', !this.currentInterface.get('nonProblemsOnly'));
      }
    }
  });

  _exports.default = _default;
});