define("partner-playbook-frontend/controllers/charter-review-database", ["exports", "partner-playbook-frontend/controllers/general-list-controller"], function (_exports, _generalListController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _generalListController.default.extend({
    queryParams: ['search', 'offset', 'limit', 'order', 'sort'],
    order: 'id',
    newCharter: null,
    newCharterVendor: null,
    retailInterface: null,
    retailProject: null,
    lodgingInterface: null,
    lodgingProject: null,
    motoInterface: null,
    motoProject: null,
    otherIndustryInterface: null,
    otherIndustryProject: null,
    foodAndBeverageInterface: null,
    foodAndBeverageProject: null,
    eCommerceInterface: null,
    eCommerceProject: null,
    isCharterReview: true,
    isCharterReviewSectionOne: false,
    isCharterReviewSectionTwo: false,
    isCharterReviewSectionThree: false,
    isCharterReviewSectionFour: false,
    isExpanded: false,
    actions: {
      charterList: function charterList() {
        this.set('isExpanded', false);
        this.set('isCharterReviewSectionOne', false);
        this.set('isCharterReviewSectionTwo', false);
        this.set('isCharterReviewSectionThree', false);
        this.set('isCharterReviewSectionFour', false);
      },
      onRowClick: function onRowClick(aCharterReview) {
        if (!this.isExpanded) {
          this.toggleProperty('isExpanded');
          this.set('isCharterReviewSectionOne', true);
          this.set('isCharterReviewSectionTwo', false);
          this.set('isCharterReviewSectionThree', false);
          this.set('isCharterReviewSectionFour', false);
        }

        this.set('newCharter', aCharterReview);
        this.set('newCharterVendor', aCharterReview.vendor);
        this.set('potentialCharterVendor', aCharterReview.vendor); //this.set('newCharter.entryData.vendor', this.store.createRecord('vendor', aCharterReview.vendor));

        this.set('newCharter.entryData.overallProject', this.store.createRecord('project', aCharterReview.entryData.overallProject));
        this.set('newCharter.entryData.overallInterface', this.store.createRecord('interface', aCharterReview.entryData.overallInterface));

        if (this.newCharter.entryData.overallInterface.isRetail) {
          this.set('retailInterface', this.store.findRecord('interface', this.newCharter.entryData.retailInterface.id));
          this.set('retailProject', this.store.findRecord('project', this.newCharter.entryData.retailProject.id));
        }

        if (this.newCharter.overallInterface.isFoodAndBeverage) {
          this.set('foodAndBeverageInterface', this.store.findRecord('interface', this.newCharter.entryData.foodAndBeverageInterface.id));
          this.set('foodAndBeverageProject', this.store.findRecord('project', this.newCharter.entryData.foodAndBeverageProject.id));
        }

        if (this.newCharter.overallInterface.isLodging) {
          this.set('lodgingInterface', this.store.findRecord('interface', this.newCharter.entryData.lodgingInterface.id));
          this.set('lodgingProject', this.store.findRecord('project', this.newCharter.entryData.lodgingProject.id));
        }

        if (this.newCharter.overallInterface.isMoto) {
          this.set('motoInterface', this.store.findRecord('interface', this.newCharter.entryData.motoInterface.id));
          this.set('motoProject', this.store.findRecord('project', this.newCharter.entryData.motoProject.id));
        }

        if (this.newCharter.overallInterface.isOtherIndustry) {
          this.set('otherIndustryInterface', this.store.findRecord('interface', this.newCharter.entryData.otherIndustryInterface.id));
          this.set('otherIndustryProject', this.store.findRecord('project', this.newCharter.entryData.otherIndustryProject.id));
        }

        if (this.newCharter.overallInterface.isECommerce) {
          this.set('eCommerceInterface', this.store.findRecord('interface', this.newCharter.entryData.eCommerceInterface.id));
          this.set('eCommerceProject', this.store.findRecord('project', this.newCharter.entryData.eCommerceProject.id));
        }
      },
      showCharterReviewNextSection: function showCharterReviewNextSection() {
        if (this.isExpanded && this.isCharterReviewSectionOne) {
          this.set('isCharterReviewSectionOne', false);
          this.set('isCharterReviewSectionTwo', true);
          this.set('isCharterReviewSectionThree', false);
          this.set('isCharterReviewSectionFour', false);
        } else if (this.isExpanded && this.isCharterReviewSectionTwo) {
          this.set('isCharterReviewSectionOne', false);
          this.set('isCharterReviewSectionTwo', false);
          this.set('isCharterReviewSectionThree', true);
          this.set('isCharterReviewSectionFour', false);
        } else if (this.isExpanded && this.isCharterReviewSectionThree) {
          this.set('isCharterReviewSectionOne', false);
          this.set('isCharterReviewSectionTwo', false);
          this.set('isCharterReviewSectionThree', false);
          this.set('isCharterReviewSectionFour', true);
          this.set('isCharterReview', false);
        }
      },
      submitCharterReview: function submitCharterReview() {
        if (this.isExpanded && this.isCharterReviewSectionFour) {
          this.set('isExpanded', false);
          this.set('isCharterReviewSectionOne', false);
          this.set('isCharterReviewSectionTwo', false);
          this.set('isCharterReviewSectionThree', false);
          this.set('isCharterReviewSectionFour', false);
          this.set('isCharterReview', true);
        }
      }
    }
  });

  _exports.default = _default;
});