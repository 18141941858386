define("partner-playbook-frontend/templates/components/charter-intake-development-vendor-address-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5y+aRxo9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\"],[9],[0,\"Business Address\"],[10],[0,\"\\n\"],[7,\"label\"],[11,\"for\",\"officeAddress\"],[9],[0,\"Office Address:\"],[10],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"value\"],[[23,[\"officeAddress\"]]]]],false],[7,\"br\"],[9],[10],[0,\"\\n\\n  \"],[7,\"label\"],[11,\"for\",\"city\"],[9],[0,\"City:\"],[10],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"value\"],[[23,[\"city\"]]]]],false],[7,\"br\"],[9],[10],[0,\"\\n\\n  \"],[7,\"label\"],[11,\"for\",\"state\"],[9],[0,\"State:\"],[10],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"value\"],[[23,[\"state\"]]]]],false],[7,\"br\"],[9],[10],[0,\"\\n\\n  \"],[7,\"label\"],[11,\"for\",\"zipCode\"],[9],[0,\"Zip Code:\"],[10],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"value\"],[[23,[\"zipCode\"]]]]],false],[7,\"br\"],[9],[10],[0,\"\\n\\n  \"],[7,\"label\"],[11,\"for\",\"country\"],[9],[0,\"Country:\"],[10],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"value\"],[[23,[\"country\"]]]]],false],[7,\"br\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "partner-playbook-frontend/templates/components/charter-intake-development-vendor-address-details.hbs"
    }
  });

  _exports.default = _default;
});