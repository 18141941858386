define("partner-playbook-frontend/controllers/general-list-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['search', 'offset', 'limit', 'order', 'sort'],
    router: Ember.inject.service(),
    offset: 0,
    limit: 10,
    search: null,
    order: 'id',
    sort: 'asc',
    sortAsc: Ember.computed.equal('sort', 'asc'),
    actions: {
      onTermUpdated: function onTermUpdated(search) {
        this.set('search', search);
        this.set('offset', 0);
      },
      filterChanged: function filterChanged(filter) {
        var limit = filter.limit,
            offset = filter.offset,
            sortAsc = filter.sortAsc,
            order = filter.sortBy;
        var sort = sortAsc ? 'asc' : 'desc';
        this.setProperties({
          limit: limit,
          offset: offset,
          sort: sort,
          order: order
        });
      },
      onRowClick: function onRowClick(record) {//this.transitionToRoute(`${this.router.currentRoute.parent.name}.edit`, record.id);
      }
    }
  });

  _exports.default = _default;
});