define("partner-playbook-frontend/components/charter-review-project-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['item-form', 'ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    enableDropdown: true,
    errorMessage: null,
    hasProjectDeadlineRetail: false,
    hasProjectDeadlineECommerce: false,
    hasProjectDeadlineMoto: false,
    hasProjectDeadlineFoodAndBeverage: false,
    hasProjectDeadlineLodging: false,
    hasProjectDeadlineOtherIndustry: false,
    hasMerchantAssociation: false,
    isRetail: false,
    isFoodAndBeverage: false,
    isLodging: false,
    isMoto: false,
    isECommerce: false,
    isOtherIndustry: false,
    hasTopologyDiagram: false,
    isCharterReviewSectionTwo: false,
    isCharterReview: false,
    init: function init() {
      this._super.apply(this, arguments);

      console.log(this.newCharter.entryData);
      this.set('newOverallInterface.name', this.newCharter.entryData.overallInterface.name);
      this.set('newOverallInterface.version', this.newCharter.entryData.overallInterface.version);
      this.set('newOverallProject.hasMerchantAssociation', this.newCharter.entryData.overallProject.hasMerchantAssociation);
      this.set('newOverallProject.cardTokenUsage', this.newCharter.entryData.overallProject.cardTokenUsage);
      this.set('newOverallProject.previousPaymentServices', this.newCharter.entryData.overallProject.previousPaymentServices);
      this.set('newOverallProject.hasTopologyDiagram', this.newCharter.entryData.overallProject.hasTopologyDiagram);
      this.set('newOverallInterface.isRetail', this.newCharter.entryData.overallInterface.isRetail);
      this.set('newOverallInterface.isFoodAndBeverage', this.newCharter.entryData.overallInterface.isFoodAndBeverage);
      this.set('newOverallInterface.isLodging', this.newCharter.entryData.overallInterface.isLodging);
      this.set('newOverallInterface.isMoto', this.newCharter.entryData.overallInterface.isMoto);
      this.set('newOverallInterface.isEcommerce', this.newCharter.entryData.overallInterface.isEcommerce);
      this.set('newOverallInterface.isOtherIndustryType', this.newCharter.entryData.overallInterface.isOtherIndustryType);
      this.set('hasMerchantAssociation', this.newOverallProject.hasMerchantAssociation);
      this.set('isRetail', this.newOverallInterface.isRetail);
      this.set('isFoodAndBeverage', this.newOverallInterface.isFoodAndBeverage);
      this.set('isLodging', this.newOverallInterface.isLodging);
      this.set('isMoto', this.newOverallInterface.isLodging);
      this.set('isECommerce', this.newOverallInterface.isECommerce);
      this.set('isOtherIndustry', this.newOverallInterface.isOther);
      this.set('hasTopologyDiagram', this.newOverallProject.hasTopologyDiagram); //this.setupRetail(this.newCharter.entryData.retailInterface.id, this.newCharter.entryData.retailProject.id);
    },
    actions: {
      toggleHasProjectDeadlineRetail: function toggleHasProjectDeadlineRetail() {
        console.log(this);
        this.toggleProperty('hasProjectDeadlineRetail');
        this.retailProject.set('hasProjectDeadline', !this.retailProject.get('hasProjectDeadline'));
        console.log(this.retailInterface);
      },
      toggleHasProjectDeadlineFoodAndBeverage: function toggleHasProjectDeadlineFoodAndBeverage() {
        this.toggleProperty('hasProjectDeadlineFoodAndBeverage');
        this.foodAndBeverageProject.set('hasProjectDeadline', !this.foodAndBeverageProject.get('hasProjectDeadline'));
      },
      toggleHasProjectDeadlineMoto: function toggleHasProjectDeadlineMoto() {
        this.toggleProperty('hasProjectDeadlineMoto');
        this.MotoProject.set('hasProjectDeadline', !this.motoProject.get('hasProjectDeadline'));
      },
      toggleHasProjectDeadlineECommerce: function toggleHasProjectDeadlineECommerce() {
        this.toggleProperty('hasProjectDeadlineECommerce');
        this.eCommerceProject.set('hasProjectDeadline', !this.eCommerceProject.get('hasProjectDeadline'));
      },
      toggleHasProjectDeadlineLodging: function toggleHasProjectDeadlineLodging() {
        this.toggleProperty('hasProjectDeadlineLodging');
        this.lodgingProject.set('hasProjectDeadline', !this.lodgingProject.get('hasProjectDeadline'));
      },
      toggleHasProjectDeadlineOtherIndustry: function toggleHasProjectDeadlineOtherIndustry() {
        this.toggleProperty('hasProjectDeadlineOtherIndustry');
        this.otherIndustryProject.set('hasProjectDeadline', !this.otherIndustryProject.get('hasProjectDeadline'));
      },
      toggleHasMerchantAssociation: function toggleHasMerchantAssociation() {
        this.toggleProperty('hasMerchantAssociation');
        this.newOverallProject.set('isMerchantAssociatedProject', !this.newOverallProject.get('isMerchantAssociatedProject'));
      },
      toggleIsRetail: function toggleIsRetail() {
        this.toggleProperty('isRetail');
        this.newOverallInterface.set('isRetail', !this.newOverallInterface.get('isRetail'));
      },
      toggleIsFoodAndBeverage: function toggleIsFoodAndBeverage() {
        this.toggleProperty('isFoodAndBeverage');
        this.newOverallInterface.set('isFoodAndBeverage', !this.newOverallInterface.get('isFoodAndBeverage'));
      },
      toggleIsLodging: function toggleIsLodging() {
        this.toggleProperty('isLodging');
        this.newOverallInterface.set('isLodging', !this.newOverallInterface.get('isLodging'));
      },
      toggleIsMoto: function toggleIsMoto() {
        this.toggleProperty('isMoto');
        this.newOverallInterface.set('isMoto', !this.newOverallInterface.get('isMoto'));
      },
      toggleIsECommerce: function toggleIsECommerce() {
        this.toggleProperty('isECommerce');
        this.newOverallInterface.set('isECommerce', !this.newOverallInterface.get('isECommerce'));
      },
      toggleIsOtherIndustryType: function toggleIsOtherIndustryType() {
        this.toggleProperty('isOtherIndustryType');
        this.newOverallInterface.set('isOtherIndustryType', !this.newOverallInterface.get('isOtherIndustryType'));
      },
      toggleHasTopologyDiagram: function toggleHasTopologyDiagram() {
        this.toggleProperty('hasTopologyDiagram');
        this.newOverallInterface.set('hasTopologyDiagram', !this.newOverallInterface.get('hasTopologyDiagram'));
      },
      uploadTopologyDiagram: function uploadTopologyDiagram() {
        //TODO implement db storage
        alert('This is not yet implemented');
      },
      showCharterReviewSectionTwo: function showCharterReviewSectionTwo() {
        this.set('isCharterReviewSectionTwo', true);
      },
      hideCharterReviewSectionTwo: function hideCharterReviewSectionTwo() {
        this.set('isCharterReviewSectionTwo', false);
      }
    }
  });

  _exports.default = _default;
});