define("partner-playbook-frontend/models/contact", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var belongsTo = _emberData.default.belongsTo;
  var attr = _emberData.default.attr;
  /* This Model appears to be the fields in components/contact-table.js */

  var _default = _emberData.default.Model.extend({
    vendorId: belongsTo('vendor', false),
    firstName: attr(),
    lastName: attr(),
    primaryPhone: attr(),
    secondaryPhone: attr(),
    title: attr(),
    email: attr(),
    internalResource: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    //Not Updateable
    searchMatch: attr(),
    name: attr()
  });

  _exports.default = _default;
});