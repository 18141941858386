define("partner-playbook-frontend/templates/components/custom-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/3L+BM0O",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"\\n\"],[7,\"div\"],[12,\"class\",[28,[\"custom-header text-\",[21,\"color\"]]]],[9],[0,\"\\n  Cell \"],[14,1],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "partner-playbook-frontend/templates/components/custom-cell.hbs"
    }
  });

  _exports.default = _default;
});