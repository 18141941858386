define("partner-playbook-frontend/components/ui/form-fields/static-field", ["exports", "partner-playbook-frontend/components/ui/form-fields/field"], function (_exports, _field) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _field.default.extend({
    fullWidth: true,
    dataTest: 'form-field-static-field',
    inputClass: Ember.computed('fullWidth', 'fieldWidth', function getter() {
      if (this.fieldWidth) {
        return "".concat(this.fieldWidth, " wide column");
      }

      if (!this.label) {
        return 'sixteen wide column';
      }

      return "".concat(this.fullWidth ? 'twelve' : 'eight', " wide column");
    })
  });

  _exports.default = _default;
});