define("partner-playbook-frontend/adapters/application", ["exports", "ember-data", "partner-playbook-frontend/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend({
    host: "".concat(_environment.default.host, "/partner-playbook-api/v1"),
    init: function init() {
      this._super.apply(this, arguments);
      /*
      		this.set('headers', {
      
      		});*/

    }
  });

  _exports.default = _default;
});