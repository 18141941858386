define("partner-playbook-frontend/components/vendor-details-contacts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['item-form', 'ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    enableDropdown: true,
    errorMessage: null,
    contactHeaders: [{
      name: 'First Name',
      field: 'firstName',
      width: 'four',
      sortable: false
    }, {
      name: 'Last Name',
      field: 'lastName',
      width: 'four',
      sortable: false
    }, {
      name: 'Primary Phone',
      field: 'primaryPhone',
      width: 'four',
      sortable: false
    }, {
      name: 'Title',
      field: 'title',
      width: 'four',
      sortable: false
    }, {
      name: 'Email',
      field: 'email',
      width: 'four',
      sortable: false
    }, {
      name: 'Role',
      field: 'role',
      width: 'four',
      sortable: false
    }],
    headers: Ember.computed(function () {
      return this.contactHeaders;
    }),
    vendorContacts: Ember.computed(function () {
      var _this = this;

      var vendorContactRelations = this.interfaceGeneralInfo ? this.vendorContactRelations.filter(function (obj) {
        return obj.vendorId == _this.interfaceGeneralInfo.id;
      }) : [];
      return vendorContactRelations;
    }),
    actions: {
      highlightChange: function highlightChange(record) {
        record.target.style.backgroundColor = "#f08686";
      },
      addContactDetails: function addContactDetails() {
        this.notifyPropertyChange('vendorContacts');
      }
    }
  });

  _exports.default = _default;
});