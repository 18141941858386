define("partner-playbook-frontend/components/project-details-general", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['item-form', 'ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    enableDropdown: true,
    errorMessage: null,
    currentProject: null,
    apiAnalyst: Ember.computed(function () {
      var _this = this;

      return this.currentProject ? this.contacts.find(function (obj) {
        return obj.id == _this.currentProject.apiPocId;
      }) : null;
    }),
    integrationEngineer: Ember.computed(function () {
      var _this2 = this;

      return this.currentProject ? this.contacts.find(function (obj) {
        return obj.id == _this2.currentProject.bdPocId;
      }) : null;
    }),
    actions: {
      highlightChange: function highlightChange(record) {
        record.target.style.backgroundColor = "#f08686";
      }
    }
  });

  _exports.default = _default;
});