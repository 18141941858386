define("partner-playbook-frontend/components/contact-database/contact-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    limit: 10,
    offset: 0,
    sortBy: 'firstName',
    sortAsc: true,
    search: null,
    searchEnabled: true,

    /* Add the Header Names and Field to the Table */
    contactHeaders: [{
      name: 'First Name',
      field: 'firstName',
      width: 'four',
      sortable: false
    }, {
      name: 'Last Name',
      field: 'lastName',
      width: 'four',
      sortable: false
    }, {
      name: 'Primary Phone',
      field: 'primaryPhone',
      width: 'four',
      sortable: false
    }, {
      name: 'Secondary Phone',
      field: 'SecondaryPhone',
      width: 'four',
      sortable: false
    }, {
      name: 'Title',
      field: 'title',
      width: 'four',
      sortable: false
    }, {
      name: 'Email',
      field: 'email',
      width: 'four',
      sortable: false
    }, {
      name: 'Internal Resource',
      field: 'internalResource',
      width: 'four',
      sortable: false
    }, {
      name: 'Created At',
      field: 'createdAt',
      width: 'four',
      sortable: false
    }, {
      name: 'Updated At',
      field: 'updatedAt',
      width: 'four',
      sortable: false
    }],
    headers: Ember.computed(function () {
      return this.contactHeaders;
    }),
    actions: {
      handleRowClick: function handleRowClick(aContact) {
        this.handleRowClick(aContact);
      },
      onTermUpdated: function onTermUpdated(search) {
        this.set('search', search);
        this.set('offset', 0);
      },
      filterChanged: function filterChanged(filter) {
        this.filterChanged(filter);
      }
    }
  });

  _exports.default = _default;
});