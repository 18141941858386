define("partner-playbook-frontend/models/office", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    Id: attr(),
    officeCode: attr(),
    vendorId: attr(),
    streetAddress: attr(),
    city: attr(),
    state: attr(),
    zipCode: attr(),
    country: attr(),
    primaryOffice: attr(),
    createdAt: attr(),
    updatedAt: attr(),
    InReview: attr()
  });

  _exports.default = _default;
});