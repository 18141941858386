define("partner-playbook-frontend/templates/components/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HfdIPPRr",
    "block": "{\"symbols\":[\"user\",\"&default\",\"@users\"],\"statements\":[[14,2],[0,\"\\n\\n\"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[22,3,[]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[0,\"\\n      \"],[1,[22,1,[\"name\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "partner-playbook-frontend/templates/components/users.hbs"
    }
  });

  _exports.default = _default;
});