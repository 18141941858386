define("partner-playbook-frontend/templates/admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OliF3rR9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\"],[9],[0,\"Admin\"],[10],[0,\"\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "partner-playbook-frontend/templates/admin.hbs"
    }
  });

  _exports.default = _default;
});