define("partner-playbook-frontend/models/vendor-contact-relation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;

  var _default = _emberData.default.Model.extend({
    vendorId: attr(),
    contactId: attr(),
    contact: attr(),
    vendor: attr(),
    role: attr()
  });

  _exports.default = _default;
});