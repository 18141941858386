define("partner-playbook-frontend/templates/charter-intake", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eaVcHLmn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"interface-database\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"view\"],[9],[0,\"\\n    \"],[7,\"h1\"],[9],[0,\"Charter Entry Details\"],[10],[7,\"br\"],[9],[10],[0,\"\\n\\n\"],[4,\"unless\",[[23,[\"charterSubmitted\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"interfaceDetailsView\"],[11,\"style\",\"display: flex;\"],[9],[0,\"\\n        \"],[5,\"charter-intake-vendor-details\",[],[[\"@title\",\"@newCharter\"],[\"Vendor Details\",[21,\"newCharter\"]]]],[0,\"\\n        \"],[5,\"charter-intake-project-details\",[],[[\"@title\",\"@newCharter\"],[\"Project Details\",[21,\"newCharter\"]]]],[0,\"\\n\\n        \"],[2,\"\\n\\t\\t\\t<CharterIntakeInternalPersonnelDetails @title=\\\"Shift4 Personnel\\\" />\\n\\t\\t\\t<CharterIntakeProjectDetails @title=\\\"Partner/Project Details\\\" />\\n\\t\\t\\t<CharterIntakeIndustryDetails />\\n\\t\\t\"],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"style\",\"text-align:center;\"],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"formButtons\"],[9],[0,\"Submit New\\n          Charter\"],[3,\"action\",[[22,0,[]],\"submitNewCharter\"]],[10],[0,\"\\n      \"],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"charterSubmitted\"]]],null,{\"statements\":[[0,\"      \"],[7,\"h2\"],[9],[0,\"Charter Submitted\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "partner-playbook-frontend/templates/charter-intake.hbs"
    }
  });

  _exports.default = _default;
});