define("partner-playbook-frontend/components/projects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    searchString: "",
    resizeEnabled: true,
    reorderEnabled: false,
    resizeModeFluid: false,
    rowSelectionMode: 'single',
    checkboxSelectionMode: 'none',
    selectingChildrenSelectsParent: false,
    newRows: [],
    actions: {
      openProjects: function openProjects(projectStatus) {
        var i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabcontent");

        for (i = 0; i < tabcontent.length; i++) {
          tabcontent[i].getElementsByClassName.display = "none";
        }

        tablinks = document.getElementsByClassName("tablinks");

        for (i = 0; i < tablinks.length; i++) {
          tablinks[i].className = tablinks[i].className.replace(" active", "");
        }

        document.getElementById(projectStatus).style.display = "block"; //evt.currentTarget.className += " active";
      },
      updateRows: function updateRows(newSearchString) {
        newSearchString = newSearchString.trim();
        this.searchString = newSearchString;
        this.newRows = this.updateRows(this.model, this.searchString);
        this.notifyPropertyChange('rows');
      }
    },
    rows: Ember.computed(function () {
      if (!this.newRows || this.newRows.length === 0) {
        return this.model;
      }

      return this.newRows;
    }),
    columns: Ember.computed(function () {
      return [{
        name: "Ranking",
        valuePath: "ranking"
      }, {
        name: "BD Ranking",
        valuePath: "bdRanking"
      }, {
        name: "Vendor Name",
        valuePath: "isvId"
      }, {
        name: "Interface Name",
        valuePath: "isvId"
      }, {
        name: "Interface Version",
        valuePath: "interfaceVersion"
      }, {
        name: "Industry Type",
        valuePath: "industryType"
      }, {
        name: "Project Summary",
        valuePath: "type"
      }, {
        name: "Project Status",
        valuePath: "projectStatus"
      }, {
        name: "Channel Sales Director",
        valuePath: "channelSalesDirector"
      }, {
        name: "Integration Engineer",
        valuePath: "integrationsEngineer"
      }, {
        name: "API Analyst",
        valuePath: "apiAnalyst"
      }, {
        name: "Pilot Analyst",
        valuePath: "pilotAnalyst"
      }];
    }),
    selectRows: function selectRows(selection) {
      this.selection = selection;
    },
    updateRows: function updateRows(data, searchString) {
      if (!searchString) {
        return this.model;
      }

      var newRows = [];
      data.forEach(function (item) {
        if (item.isvPartner) {
          if (item.isvPartner.includes(searchString)) {
            //this.rows.push(item);
            newRows.push(item);
          }
        }
      });

      if (newRows.length === 0) {
        var emptyResultItem = {
          isvPartner: "No Results"
        };
        newRows.push(emptyResultItem);
      }

      return newRows;
    }
  });

  _exports.default = _default;
});