define("partner-playbook-frontend/templates/components/nav-bar-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3wYNYjsV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"nav\"],[11,\"class\",\"menu\"],[9],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"links\"],[9],[0,\"\\n\\t\\t\"],[4,\"link-to\",[\"index\"],[[\"class\"],[\"menu\"]],{\"statements\":[[0,\"Home\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[4,\"link-to\",[\"contacts\"],[[\"class\"],[\"menu\"]],{\"statements\":[[0,\"Contacts\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[4,\"link-to\",[\"projects\"],[[\"class\"],[\"menu\"]],{\"statements\":[[0,\"Submit A Change\"]],\"parameters\":[]},null],[0,\"\\n\\t\\t\"],[4,\"link-to\",[\"release-notes\"],[[\"class\"],[\"menu\"]],{\"statements\":[[0,\"Release Notes\"]],\"parameters\":[]},null],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "partner-playbook-frontend/templates/components/nav-bar-footer.hbs"
    }
  });

  _exports.default = _default;
});