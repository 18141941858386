define("partner-playbook-frontend/controllers/interfaces", ["exports", "partner-playbook-frontend/controllers/general-list-controller"], function (_exports, _generalListController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable max-len, no-unused-vars */
  var _default = _generalListController.default.extend({
    queryParams: ['search', 'offset', 'limit', 'order', 'sort'],
    order: 'id',
    actions: {
      onTermUpdated: function onTermUpdated(search) {
        this.set('search', search);
        this.set('offset', 0);
      },
      filterChanged: function filterChanged(filter) {
        var limit = filter.limit,
            offset = filter.offset,
            sortAsc = filter.sortAsc,
            order = filter.sortBy;
        var sort = sortAsc ? 'asc' : 'desc';
        this.setProperties({
          limit: limit,
          offset: offset,
          sort: sort,
          order: order
        });
      }
    }
  });

  _exports.default = _default;
});