define("partner-playbook-frontend/components/interface-database/interface-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    limit: 10,
    offset: 0,
    sortBy: 'applicationName',
    sortAsc: true,
    search: null,
    searchEnabled: true,
    interfaceHeaders: [{
      name: 'Interface Record',
      field: 'id',
      width: 'four',
      sortable: false
    }, {
      name: 'ISV Name',
      field: 'vendor.name',
      width: 'four',
      sortable: false
    }, {
      name: 'Interface Name',
      field: 'name',
      width: 'four',
      sortable: false
    }, {
      name: 'ApplicationName',
      field: 'applicationName',
      width: 'four',
      sortable: false
    }, {
      name: 'Interface Version',
      field: 'version',
      width: 'four',
      sortable: false
    }, {
      name: 'inReview',
      field: 'inReview',
      width: 'four',
      sortable: false
    }],
    headers: Ember.computed(function () {
      return this.interfaceHeaders;
    }),
    actions: {
      handleRowClick: function handleRowClick(aInterface) {
        this.handleRowClick(aInterface);
      },
      onTermUpdated: function onTermUpdated(search) {
        this.set('search', search);
        this.set('offset', 0);
      },
      filterChanged: function filterChanged(filter) {
        this.filterChanged(filter);
      }
    }
  });

  _exports.default = _default;
});