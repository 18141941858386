define("partner-playbook-frontend/components/charter-intake-project-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['item-form', 'ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    enableDropdown: true,
    errorMessage: null,
    hasProjectDeadline: false,
    hasMerchantAssociation: false,
    isRetail: false,
    isFoodAndBeverage: false,
    isLodging: false,
    isMoto: false,
    isECommerce: false,
    isOther: false,
    hasTopologyDiagram: false,
    actions: {
      toggleHasProjectDeadline: function toggleHasProjectDeadline() {
        this.toggleProperty('hasProjectDeadline');
        this.newCharter.entryData.overallProject.set('hasProjectDeadline', !this.newCharter.entryData.overallProject.get('hasProjectDeadline'));
      },
      toggleHasMerchantAssociation: function toggleHasMerchantAssociation() {
        this.toggleProperty('hasMerchantAssociation');
        this.newCharter.entryData.overallProject.set('isMerchantAssociatedProject', !this.newCharter.entryData.overallProject.get('isMerchantAssociatedProject'));
      },
      toggleIsRetail: function toggleIsRetail() {
        this.toggleProperty('isRetail');
        this.newCharter.entryData.overallInterface.set('isRetail', !this.newCharter.entryData.overallInterface.get('isRetail'));
      },
      toggleIsFoodAndBeverage: function toggleIsFoodAndBeverage() {
        this.toggleProperty('isFoodAndBeverage');
        this.newCharter.entryData.overallInterface.set('isFoodAndBeverage', !this.newCharter.entryData.overallInterface.get('isFoodAndBeverage'));
      },
      toggleIsLodging: function toggleIsLodging() {
        this.toggleProperty('isLodging');
        this.newCharter.entryData.overallInterface.set('isLodging', !this.newCharter.entryData.overallInterface.get('isLodging'));
      },
      toggleIsMoto: function toggleIsMoto() {
        this.toggleProperty('isMoto');
        this.newCharter.entryData.overallInterface.set('isMoto', !this.newCharter.entryData.overallInterface.get('isMoto'));
      },
      toggleIsECommerce: function toggleIsECommerce() {
        this.toggleProperty('isECommerce');
        this.newCharter.entryData.overallInterface.set('isECommerce', !this.newCharter.entryData.overallInterface.get('isECommerce'));
      },
      toggleIsOtherIndustryType: function toggleIsOtherIndustryType() {
        this.toggleProperty('isOtherIndustryType');
        this.newCharter.entryData.overallInterface.set('isOtherIndustryType', !this.newCharter.entryData.overallInterface.get('isOtherIndustryType'));
      },
      toggleHasTopologyDiagram: function toggleHasTopologyDiagram() {
        this.toggleProperty('hasTopologyDiagram');
        this.newCharter.entryData.overallInterface.set('hasTopologyDiagram', !this.newCharter.entryData.overallInterface.get('hasTopologyDiagram'));
      },
      uploadTopologyDiagram: function uploadTopologyDiagram() {
        //TODO implement db storage
        alert('This is not yet implemented');
      }
    }
  });

  _exports.default = _default;
});