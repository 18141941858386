define("partner-playbook-frontend/components/charter-review-vendor-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['item-form', 'ui', 'form', 'padded', 'stackable', 'grid'],
    showErrors: false,
    enableDropdown: true,
    errorMessage: null,
    currentVendor: null,
    newContact: {},
    newVendorOffice: {},
    inputContact: false,
    inputVendor: false,
    newVendorPlaceholder: {
      id: 0,
      name: 'New Vendor'
    },
    isNewVendor: true,
    limit: 10,
    offset: 0,
    sortBy: 'name',
    sortAsc: true,
    search: null,
    searchEnabled: true,
    officeEntryDataFormatted: Ember.computed(function () {
      return "Office: ".concat(JSON.stringify(this.newCharter.entryData.office, null, '\t'));
    }),
    vendorEntryDataFormatted: Ember.computed(function () {
      //alert('reading vendorEntryDataFormatted');
      console.log(this.newCharter);
      console.log("Vendor: ".concat(JSON.stringify(this.newCharter.entryData.vendor, null, '\t')));
      return "Vendor: ".concat(JSON.stringify(this.newCharter.entryData.vendor, null, '\t'));
    }),
    contactHeaders: [{
      name: 'First Name',
      field: 'firstName',
      width: 'four',
      sortable: false
    }, {
      name: 'Last Name',
      field: 'lastName',
      width: 'four',
      sortable: false
    }, {
      name: 'Primary Phone',
      field: 'primaryPhone',
      width: 'four',
      sortable: false
    }, {
      name: 'Secondary Phone',
      field: 'secondaryPhone',
      width: 'four',
      sortable: false
    }, {
      name: 'Title',
      field: 'title',
      width: 'four',
      sortable: false
    }, {
      name: 'Email',
      field: 'email',
      width: 'four',
      sortable: false
    }, {
      name: 'Role',
      field: 'email',
      width: 'four',
      sortable: false
    }],
    vendorContactRelationsFlat: Ember.computed(function () {
      return this.vendorContactRelationsPlaceholder;
    }),
    vendorContactRelationsPlaceholder: [],
    headers: Ember.computed(function () {
      return this.contactHeaders;
    }),
    init: function init() {
      this._super.apply(this, arguments);
    },
    channelSalesDirector: Ember.computed(function () {
      var _this = this;

      return this.currentVendor ? this.contacts.find(function (obj) {
        return obj.id == _this.currentVendor.channelSalesDirectorId;
      }) : null;
    }),
    relationshipManager: Ember.computed(function () {
      var _this2 = this;

      return this.currentVendor ? this.contacts.find(function (obj) {
        return obj.id == _this2.currentVendor.relationshipManagerId;
      }) : null;
    }),
    projectManager: Ember.computed(function () {
      var _this3 = this;

      return this.currentVendor ? this.contacts.find(function (obj) {
        return obj.id == _this3.currentVendor.projectManagerId;
      }) : null;
    }),
    actions: {
      onChange: function onChange(value) {
        if (this.onChange) {
          this.onChange(value, this.property);
        } else {
          this.changeset.set(this.property, value);
        }
      },
      changeContact: function changeContact() {//do nothing
        //alert("contact Changed");
      },
      changeVendorSelection: function changeVendorSelection(vendor) {
        //alert("Changed Vendor Selection");
        //this.set('newCharter.entryData.vendor', vendor);
        //this.set('isNewVendor', false);
        this.notifyPropertyChange('channelSalesDirector');
        this.notifyPropertyChange('relationshipManager');
        this.notifyPropertyChange('projectManager');
        this.set('currentVendor', vendor);
      },
      handleAddAnotherOffice: function handleAddAnotherOffice() {//this.newCharter.set('addAnotherOffice', !this.newCharter.get('addAnotherOffice'));
      },
      toggleIsNdaSigned: function toggleIsNdaSigned() {
        this.newVendor.set('isNdaSigned', !this.newVendor.get('isNdaSigned'));
      },
      toggleIsNdaSignedCurrentVendor: function toggleIsNdaSignedCurrentVendor() {
        this.currentVendor.set('isNdaSigned', !this.currentVendor.get('isNdaSigned'));
      },
      toggleIsDevelopmentVendorDifferent: function toggleIsDevelopmentVendorDifferent() {
        this.newCharter.entryData.vendor.set('isDevelopmentVendorDifferent', !this.newCharter.entryData.vendor.get('isDevelopmentVendorDifferent'));
      },
      ExpandVendorDetails: function ExpandVendorDetails() {// this.set('isNewVendor', true);
      },
      addContactDetails: function addContactDetails() {
        //alert("adding contact to database");
        //this.vendorContactRelationsPlaceholder.pushObject(this.newContact);
        //this.set('newCharter.entryData.vendorContacts',this.vendorContactRelationsPlaceholder);
        //this.notifyPropertyChange('vendorContactRelationsFlat');
        //this.set('inputContact', false);
        this.set('inputContact', false);
      },
      inputContactDetails: function inputContactDetails() {
        this.set('newContact', {});
        this.set('inputContact', true);
      },
      addVendorDetails: function addVendorDetails() {
        //alert("adding Vendor/Office to database");
        //this.vendorContactRelationsPlaceholder.pushObject(this.newContact);
        //this.set('newCharter.entryData.vendorContacts',this.vendorContactRelationsPlaceholder);
        //this.notifyPropertyChange('vendorContactRelationsFlat');
        //this.set('inputContact', false);
        this.set('inputVendor', false);
      },
      inputVendorDetails: function inputVendorDetails() {
        this.set('currentVendor', null);
        this.set('newVendorOffice', {});
        this.set('inputVendor', true);
      },
      changeProperty: function changeProperty(record, value, property) {
        record[property] = value;
      },
      filterChanged: function filterChanged(filter) {
        this.filterChanged(filter);
      }
    }
  });

  _exports.default = _default;
});