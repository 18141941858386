define("partner-playbook-frontend/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3h3Wae5W",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n  \"],[5,\"nav-bar\",[],[[],[]]],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"body\"],[9],[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[5,\"nav-bar-footer\",[],[[],[]]],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "partner-playbook-frontend/templates/application.hbs"
    }
  });

  _exports.default = _default;
});